import {useRouter} from "next/router";
import {useEffect} from "react";
import routes from "routes";

export default function Error404() {
  const router = useRouter();

  useEffect(() => {
    router.push(
      routes.home.route
    );
  }, []);

  return null;
}
