import {useEffect} from "react";

export default function Error404() {

  return (
    <div>
      The error has been reported.
      <br />
      Please try reloading the page or contact support@qoohoo.in
    </div>
  );
}
