import { useEffect } from "react";
import NextErrorComponent from "next/error";
// import * as Sentry from "@sentry/nextjs";
import { useRouter } from "next/router";

import Error404 from "../components/404";
import Error500 from "../components/500";

import useSessionStore from "../store/sessionStore";
// import { sentrySetUser } from "@qoohoo/common/utils/analytics";

const MyError = ({ statusCode }) => {
  const router = useRouter();
  const userData = useSessionStore((state) => state.userData);
  const pageUrl = router.asPath;
  const blacklistedUrls = ["/wp-login", "/wp-admin"];
  // const whitelistedUrls = ["/by", "/channels", "/session", "/event"];
  const isBlacklisted = blacklistedUrls.some((url) => pageUrl.includes(url));
  // const isWhitelisted = whitelistedUrls.some((url) => pageUrl.includes(url));

  function logError() {
    if (!isBlacklisted) {
      // sentrySetUser(userData?.uid, userData?.username);
/*
      Sentry.captureException(
        new Error(
          `Unexpected Client-side Error at path: ${pageUrl} and code: ${statusCode}`
        )
      );
*/
    }
  }

  useEffect(() => {
    logError();
  }, []);

  if (statusCode === 404) {
    return <Error404 />;
  }

  return <Error500 statusCode={statusCode} />;
};

MyError.getInitialProps = async ({ res, err }) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err,
  });

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true;

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (err) {
    // Sentry.captureException(err);

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    // await Sentry.flush(2000);

    return errorInitialProps;
  }

  return errorInitialProps;
};

export default MyError;
